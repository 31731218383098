import React, { memo } from "react"
import { Box, BoxProps } from "@chakra-ui/react"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"

type GatsbyImageBoxProps = Omit<BoxProps & GatsbyImageProps, "as">

const GatsbyImageBox: React.FC<GatsbyImageBoxProps> = props => {
  return <Box as={GatsbyImage} {...props} />
}

export default memo(GatsbyImageBox)
